@import url('https://fonts.googleapis.com/css?family=Spline+Sans+Mono:400,600,700');
@import url('https://fonts.googleapis.com/css?family=Nunito+Sans:400,600,700');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&display=swap');

@font-face {
  font-family: 'Gilroy';
  src: local('Gilroy Bold'),
       url('./fonts/Gilroy-Bold.woff') format('woff');
  font-style: normal;
}

html {
  font-size: 16px;
  background: #FFFFFF;
  margin: 0;
} /*16px*/

body {
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  line-height: 1.75;
  margin: 0;
}

h3 {font-size: 1.953rem;}

h4 {font-size: 1.563rem;}

h5 {font-size: 1.25rem;}

small, .text_small {font-size: 0.8rem;}

img {
  -webkit-user-drag: none !important;
  user-select: none !important;
  -moz-user-select: none !important;
  -webkit-user-select: none !important;
  -ms-user-select: none !important;
}

@keyframes glowing {
  0% {
    box-shadow: 0 0 5px rgba(223,94,0,1);
  }
  50% {
    box-shadow: 0 0 30px rgba(223,94,0,1);
  }
  100% {
    box-shadow: 0 0 5px rgba(223,94,0,1);
  }
}

.earning-xp-glow {
  animation: glowing ease-in-out infinite 1400ms;
}

.onboarding-button {
  margin-left: auto;
}

.section-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.sub-section-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  border-radius: 5px;
  border: 0.625px solid rgba(184, 154, 232, 0.40);
  background: linear-gradient(180deg, rgba(122, 61, 220, 0.00) 0.55%, rgba(21, 87, 255, 0.00) 95.31%);
  padding: 1.5rem;
  width: 30%;
}

.sub-section-text {
  font-size: 0.9rem;
  line-height: 1.8rem;
  font-weight: var(--header-font-weight);
  color: #bdbdbd;
  overflow-wrap: break-word;
}

.partner-image {
  margin: auto;
}

#main {
  display: flex;
  margin: auto;
  position: relative;
  justify-content: center;
  align-items: center
}

#spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.multi-spinner-container {
  width: 26rem;
  height: 26rem;
  position: relative;
}

.multi-spinner {
  position: absolute;
  width: calc(100% - 9.9px);
  height: calc(100% - 9.9px);
  border: 5px solid transparent;
  border-top-color: #ff5722;
  border-radius: 50%;
  -webkit-animation: spin 5s cubic-bezier(0.17, 0.49, 0.96, 0.76) infinite;
  animation: spin 5s cubic-bezier(0.17, 0.49, 0.96, 0.76) infinite;
}     
        
        
@-webkit-keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
      
@keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@media only screen and (max-width: 1483px) {

  .sub-section-container {
    width: 33%;
  }

  .sub-section-text {
    font-size: 0.75rem;
  }
}

@media only screen and (max-width: 993px) {
  .sub-section-container {
    width: 85%;
  }

  .sub-section-text {
    font-size: 0.7rem;
  }
}

@media only screen and (max-width: 872px) {
  .sub-section-container {
    width: 22rem;
  }

  .sub-section-text {
    font-size: 0.9rem;
  }
}

@media only screen and (max-width: 499px) {
  .sub-section-container {
    width: 85%;
  }
}

@media only screen and (max-width: 430px) {
  .sub-section-container {
    width: 100%;
  }
}

@media only screen and (max-width: 530px) {
  .onboarding-button {
    margin: auto;
  }
}

@media only screen and (max-width: 400px) {
  .hero-button {
    width: 9rem !important;
  }
}


/* Mobile */
@media only screen and (min-width: 320px) and (max-width: 767px) {

  .section-container {
    width: 100%;
  }

  .section-container {
    margin-bottom: var(--row-gap);
  }

  .partner-image {
    width: 80%;
    max-height: 30%;
  }

  #aave-image {
    max-height: 25%;
  }

  #marketplace-image {
    display: none;
  }
  
}


/* Tablets */
@media only screen and (min-width: 768px) and (max-width: 960px) {

  .partners-grid {
    column-gap: 1.5rem !important;
  }

  .partner-image {
    width: 100%;
    height: auto;
  }

  #aave-image {
    height: 70%;
  }
}



/* Laptops */
@media only screen and (min-width: 961px) and (max-width: 1211px) {

  .partner-image {
    /* width: 65%; */
    height: auto;
  }

  #aave-image {
    height: 85%;
  }

  html {
    font-size: 17px;
  }

}



/* Desktop and Wide screen */
@media only screen and (min-width: 1212px) {

  html {
    font-size: 21px;
  }

  #aave-image {
    height: 70%;
  }
}

.network-div:hover {
  filter: brightness(80%);
  cursor: pointer;
}

.network-div-focus {
  cursor: pointer;
  border: 1px solid var(--components-primary-orange) !important;
  background: #FFF4ED;
}

.network-div-disabled {
  filter: brightness(35%);
  pointer-events: none;
}

.network-div-disabled:hover {
  cursor: not-allowed;
}



:root {
  /* global colors */
  --components-primary-orange: #DF5E00;
  --banner-gradient-blue: linear-gradient(180deg, #001964 0%, #04278E 100%);

  /* left && right page padding */
  --page-padding: 1.5rem;

  /* menu items padding */
  --menu-item-left-padding: 1rem;

  /* button padding */
  --button-px: 1rem;
  --button-py: 0.1rem;

  /* header margin font line-height */
  --header-font-weight: 600;
  --header-line-height: 1.3;

  /* h1 attributes */
  --h1-font-size: 1.7rem;

  /* h2 attributes */
  --h2-font-size: 1.35rem;

  /* p attributes */
  --p-font-size: 1rem;

  /* p2 attributes */
  --p2-font-size: 0.8rem;

  /* grid item bottom margin */
  --grid-item-bm: 1.75rem;

  /* vertical grid spacing */
  --row-gap: 3.5rem;

  /* section bottom margin */
  --section-bm: 5rem;
}