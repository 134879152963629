
.main {
  position: relative;
  min-height: 100vh;
  height: 100%;
  background: #FFFFFF;
  overflow: hidden;
  margin: auto;
}

.container {
  max-width: 1600px;
  margin: auto;
  padding-left: var(--page-padding);
  padding-right: var(--page-padding);
}
